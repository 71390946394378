import { initializeApp, getApp, getApps } from 'firebase/app';
import {
  initializeFirestore, query, where, collection, getFirestore, getDocs, addDoc, setDoc, getDoc, doc, updateDoc,
} from 'firebase/firestore';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import getFirebaseConfig from '../utils/firebaseConfig';

const getBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

const firebaseConfig = {
  apiKey: getFirebaseConfig().apiKey,
  authDomain: getFirebaseConfig().authDomain,
  projectId: getFirebaseConfig().projectId,
  storageBucket: getFirebaseConfig().storageBucket,
  messagingSenderId: getFirebaseConfig().messagingSenderId,
  appId: getFirebaseConfig().appId,
  trialCollectionName: getFirebaseConfig().trialCollectionName,
  userCollectionName: getFirebaseConfig().userCollectionName,
  userRejectedCollectionName: getFirebaseConfig().userRejectedCollectionName,
  dataRejectedCollectionName: process.env.VUE_APP_DATA_REJECTED_COLLECTION_NAME || 'data_rejected', // TODO temporal
  emailCollectionName: getFirebaseConfig().emailCollectionName,
  measurementId: getFirebaseConfig().measurementId,
  databaseId: getFirebaseConfig().databaseId,
};

function getFirebaseApp() {
  if (!getApps().length) {
    return initializeApp(firebaseConfig);
  }
  return getApp();
}

function getFirebaseFirestore(app) {
  try {
    return getFirestore(app);
  } catch (error) {
    return initializeFirestore(app, {}, firebaseConfig.databaseId);
  }
}

async function getTrialDoc(db, trialId) {
  const collectionName = firebaseConfig.trialCollectionName;
  const trialCol = query(collection(db, collectionName), where('trial_id', '==', trialId));
  const trialSnapshot = await getDocs(trialCol);
  const trialList = trialSnapshot.docs.map((document) => document.data());
  return trialList[0];
}

async function getEmailDoc(db, userId) {
  const collectionName = firebaseConfig.userCollectionName;
  const docRef = doc(db, collectionName, userId);
  const docSnap = await getDoc(docRef);
  let document = {};
  if (docSnap.exists()) {
    const docData = docSnap.data();
    document = docData;
  }
  return document;
}
async function updateUser(db, userId) {
  const collectionName = firebaseConfig.userCollectionName;
  const docRef = doc(db, collectionName, userId);
  const document = await updateDoc(docRef, {
    verificated_email: true,
  });
  return document;
}

async function fetchUserCenterInfo(db, trialId, userId) {
  const userCol = query(collection(db, firebaseConfig.userCollectionName), where('user_id', '==', userId));
  const userSnapshot = await getDocs(userCol);
  const userList = userSnapshot.docs.map((document) => document.data());
  if (userList.length === 0) {
    return {};
  }

  const trialCol = query(collection(db, firebaseConfig.trialCollectionName), where('trial_id', '==', trialId));
  const trialSnapshot = await getDocs(trialCol);
  const trialList = trialSnapshot.docs.map((document) => document.data());
  if (trialList.length > 0) {
    const centerInfo = trialList[0].center.find((center) => center.center_name.toLowerCase()
      === userList[0].hospital_selected.name.toLowerCase());
    return centerInfo;
  }
  console.error('Error fetching center info');
  return {};
}
/* eslint-disable-next-line */
export const coreServices = {
  getTrial(trialId) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const trialDoc = getTrialDoc(db, trialId);
      return trialDoc;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async checkEmail(userId) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const userDoc = getEmailDoc(db, userId);
      return userDoc;
    } catch (error) {
      return '';
    }
  },
  async validateEmail(trialId, userId) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const userDoc = await updateUser(db, userId);
      const userCenterInfo = await fetchUserCenterInfo(db, trialId, userId);
      /* eslint-disable-next-line */
      const emailCollectionName = firebaseConfig.emailCollectionName;

      const user = await this.checkEmail(userId);
      await addDoc(collection(db, emailCollectionName), {
        to: userCenterInfo.doctor_email,
        template: {
          name: 'doctor_email',
          data: {
            trial_name: user.trial_name,
            doctor_name: userCenterInfo.doctor_name,
            doctor_surname: userCenterInfo.doctor_surname,
            name: user.name,
            surname: user.surname,
            email: user.email,
            age: user.age,
            gender: user.gender,
            phone: user.phone,
            form_answered: user.form_answered,
            user_id: userId,
            trial_id: trialId,
          },
        },
      });
      if (await isSupported()) {
        const analytics = getAnalytics(app);
        logEvent(analytics, 'user_validated', { name: 'user_validated', arguments: userId });
      }
      return userDoc;
    } catch (error) {
      return '';
    }
  },
  async saveRejectedTrial(patientData) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const collectionName = firebaseConfig.userRejectedCollectionName;
      const document = await addDoc(collection(db, collectionName), patientData);
      return document;
    } catch (error) {
      return '';
    }
  },
  async saveDataRejectedTrial(patientData, rejectData) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const collectionName = firebaseConfig.dataRejectedCollectionName;
      const document = await addDoc(collection(db, collectionName), patientData);
      this.saveRejectedTrial(rejectData);
      return document;
    } catch (error) {
      console.error('Error saving data rejected trial:', error);
      return '';
    }
  },
  async saveTrial(trialInformation) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);

      /* eslint-disable-next-line */
      const emailCollectionName = firebaseConfig.emailCollectionName;
      /* eslint-disable-next-line */
      const userCollectionName = firebaseConfig.userCollectionName;
      let formAnswered = '';
      for (let i = 0; i < trialInformation.questions.length; i += 1) {
        const question = trialInformation.questions[i];
        const answer = trialInformation.form_answer[i].option;
        formAnswered += `${question}:  ${answer}`;
        if (i < trialInformation.questions.length - 1) {
          formAnswered += ', ';
        }
      }
      const userDoc = await addDoc(collection(db, userCollectionName), {
        name: trialInformation.name,
        surname: trialInformation.surname,
        created_at: trialInformation.created_at,
        age: trialInformation.age,
        gender: trialInformation.gender,
        email: trialInformation.email,
        phone_number: trialInformation.phoneNumber,
        phone: trialInformation.phone,
        answers_with_values: trialInformation.form_answer,
        trial_id: trialInformation.trial_id,
        trial_name: trialInformation.trial_name,
        verificated_email: false,
        hospital_selected: trialInformation.hospital_selected,
        form_answered: formAnswered,
      });
      await setDoc(doc(db, userCollectionName, userDoc.id), {
        user_id: userDoc.id,
      }, { merge: true });
      await addDoc(collection(db, emailCollectionName), {
        to: trialInformation.email,
        template: {
          name: 'verify_email',
          to: trialInformation.email,
          data: {
            url: getBaseUrl(),
            trial_id: trialInformation.trial_id,
            user_id: userDoc.id,
          },
        },
      });
      return 'User Added';
    } catch (err) {
      return [];
    }
  },
  getTermsAndConditionsUrl() {
    return process.env.VUE_APP_TERMSANDCONDITIONS;
  },
  getPrivacyPolicyUrl() {
    return process.env.VUE_APP_PRIVACYPOLICY;
  },
  async checkTrialEmail(email, trialId) {
    try {
      const app = getFirebaseApp();
      const db = getFirebaseFirestore(app);
      const emailTrialQuery = query(collection(db, firebaseConfig.userCollectionName), where('email', '==', email));
      const emailSnapshot = await getDocs(emailTrialQuery);
      const trialEmaillList = emailSnapshot.docs.map((document) => document.data())
        .filter((item) => item.trial_id === trialId && item.email === email);
      const emailRejectedQuery = query(collection(db, firebaseConfig.userRejectedCollectionName), where('email', '==', email));
      const emailRejectedSnapshot = await getDocs(emailRejectedQuery);
      const rejectedEmailList = emailRejectedSnapshot.docs.map((document) => document.data())
        .filter((item) => item.trial_id === trialId && item.email === email);
      const totalResults = [...trialEmaillList, ...rejectedEmailList];
      return totalResults.length > 0;
    } catch (error) {
      return '';
    }
  },
};
