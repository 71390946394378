<template>
  <v-container fluid class="main-container">
    <div id="rotate-to-portrait">
      <span class="rotate-to-portrait-text">{{ $t('trial-screen.rotate') }}</span>
    </div>
    <v-row>
      <v-col cols="12" sm="6" class="information-trial">
        <v-fade-transition>
          <div v-if="questionAnsweredValues === totalValues
            && questionAnsweredValues > 0">
            <div id="congratulationsModal" class="modal">
              <div class="modal-trial-content">
                <v-row>
                  <v-col class="mb-0 pb-0" cols="12" md="3" justify="left">
                    <v-img class="modal-logo mb-0 pb-0" :src="require('../assets/images/patient_up_logo.png')"
                      height="7vh" :contain="true" />
                  </v-col>
                </v-row>
                <v-row>
                  <hr class="gradient-line" />
                </v-row>
                <v-row>
                  <h3 class="modal-trial-title">
                    {{ $t('positive-result-screen.congratulations') }}
                  </h3>
                </v-row>
                <v-row>
                  <div class="modal-trial-text">
                    <p>{{ $t('positive-result-screen.complete-msg') }}</p>
                    <p>{{ $t('positive-result-screen.msg') }}</p>
                    <p style="color: #33CCCC">{{ $t('positive-result-screen.more-information-msg') }}</p>
                    <p>{{ $t('positive-result-screen.thx-msg') }}</p>
                  </div>
                  <v-row class="mb-5" justify="center">
                    <v-btn class="modal-trial-button" x-large @click="trialNextStep()">
                      {{ $t('positive-result-screen.button-next') }}
                    </v-btn>
                  </v-row>
                </v-row>
              </div>
            </div>
          </div>
          <div v-if="questionAnsweredValues < totalValues">
            <div id="negativeModal" class="modal">
              <div class="modal-trial-negative-content">
                <v-row>
                  <v-col class="mb-0 pb-0" cols="12" md="3" justify="left">
                    <v-img class="modal-logo mb-0 pb-0" :src="require('../assets/images/patient_up_logo.png')"
                      height="7vh" :contain="true" />
                  </v-col>
                </v-row>
                <v-row>
                  <hr class="gradient-line" />
                </v-row>
                <v-row>
                  <h3 class="modal-trial-negative-title">
                    {{ $t('negative-result-screen.sorry-msg') }}
                  </h3>
                </v-row>
                <v-row>
                  <div class="modal-trial-negative-text">
                    <p> {{ $t('negative-result-screen.msg') }} </p>
                    <p> {{ $t('negative-result-screen.inconvenience-msg') }} </p>
                    <p> {{ $t('negative-result-screen.thx-msg') }} </p>
                  </div>
                  <v-row>
                    <v-col class="mx-7 px-7">
                      <v-checkbox class="accept-checkbox" v-model="acceptConsent"
                        :label="$t('negative-result-screen.accept')" @change="toggleCheckbox('accept')" />
                      <v-checkbox class="decline-checkbox" v-model="declineConsent"
                        :label="$t('negative-result-screen.decline')" @change="toggleCheckbox('decline')" />
                    </v-col>
                  </v-row>

                  <v-row class="mb-5" justify="center">
                    <v-btn class="modal-trial-negative-button" x-large @click="endStep()">
                      {{ $t('negative-result-screen.button-next')}}
                    </v-btn>
                  </v-row>
                </v-row>
              </div>
            </div>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="my-0 py-0">
        <v-img src="../assets/images/pantalla_3.png" class="responsive-image" aspect-ratio="1.6" :contain="false" />
      </v-col>
      <v-col cols="12" md="6" class="form-side">
        <v-row>
          <v-col cols="12" sm="6" class="language-icons">
            <locale-switcher />
          </v-col>
          <v-col cols="12" sm="6">
            <v-img class="sermes-logo" :src="require('../assets/images/patient_up_logo.png')" :contain="true" />
          </v-col>
        </v-row>
        <v-row class="trial-content">
          <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
            <v-card id="trial-form-card" class="form-card">
              <v-card-title primary-title class="justify-left mt-0 pt-0 pl-0">
                <h3 class="step-title">
                  <span id="trial-form-title">
                    {{ $t('questionary-screen.title') }}
                  </span>
                </h3>
              </v-card-title>
              <v-card-text class="questions-content">
                <v-form id="trial-form" ref="form" v-model="valid" lazy-validation>
                  <div v-for="(question, index) in questions" :key="question.form_id">
                    <v-row class="mb-1">
                      <v-col v-if="!isYesNoQuestion(question.options)" cols="12">
                        <label :for="'select-' + index">{{ question.question }}</label>
                        <v-select class="trial-form-input-select ml-0 pl-0 mb-0 pb-0 pt-1 mt-1"
                          :error="selectErrors[index]" :items="question.options"
                          :label="$t('questionary-screen.select')" item-text="option" item-value="value" hint=""
                          return-object required @change="(selection) => selectionChanged(selection, index)" />
                      </v-col>
                      <v-col v-else cols="12">
                        <div class="question-label">
                          {{ question.question }}
                        </div>
                        <v-radio-group @change="(selection) =>
                          handleRadioChange(selection, question.options, index)" row
                          :class="radioErrors[index] ? 'invalid-radio' : ''">
                          <v-radio class="mr-4" v-for="(option, i) in question.options" :key="i" :label="option.option"
                            :value="option.value" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="questions.length" class="trial-buttons justify-center">
                <v-btn id="trial-form-previous-button" x-large
                  :class="disablePrevButton ? 'trial-button button-disabled' : 'trial-button button-enabled'"
                  @click="previousStep()">
                  {{ $t('questionary-screen.button-prev') }}
                </v-btn>
                <v-btn id="trial-form-next-button" x-large
                  :class="nextBtnDisable ? 'trial-button button-disabled' : 'trial-button button-enabled'"
                  @click="nextStep()">
                  {{ $t('questionary-screen.button-next') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import { trialServices } from '../services/trialService';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';

export default {
  name: 'PatologyForm',
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      trial: '',
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', 'mdi-doctor'],
      step: 3,
      centers: [],
      questions: [],
      questionAnsweredValues: 0,
      totalValues: 0,
      selectedAnswers: [],
      nextBtnDisable: true,
      valid: false,
      isFormInvalid: false,
      selectErrors: [],
      radioErrors: [],
      acceptConsent: false,
      declineConsent: true,
      dataConsent: false,
      currentLanguage: null,
    };
  },
  computed: {
    disablePrevButton() {
      /* eslint-disable-next-line */
      return this.step !== 3 ? true : false;
    },
  },
  created() {
    this.questionAnsweredValues = 0;
    this.totalValues = 0;
    this.trial = this.$route.params.trialId;
    this.currentLanguage = this.$store.state.selectedLanguage.toUpperCase();
    trialServices.getTrialInformation(this.trial)
      .then((response) => {
        this.questions = response.form.map((question) => ({
          ...question,
          options: question.options.map((option, optionIdx) => ({
            ...option,
            optionValue: option.value,
            value: optionIdx,
          })),
        }));
        this.selectErrors = new Array(this.questions.length).fill(false);
        this.selectedAnswers = new Array(this.questions.length).fill(null);
        this.radioErrors = new Array(this.questions.length).fill(false);
        this.centers = response.center;
      })
      .catch((error) => {
        console.log(error);
      });
    this.addEvent('page_view', 'Trial Form view');
  },
  watch: {
    selectedLanguage(newVal) {
      this.currentLanguage = newVal;
    },
    selectedAnswers: {
      handler(newVal) {
        newVal.forEach((answer, index) => {
          if (answer !== null && this.selectErrors[index]) {
            this.$set(this.selectErrors, index, false);
          }
        });
      },
      deep: true,
    },
    acceptConsent(newVal) {
      if (newVal) {
        this.acceptConsent = true;
        this.declineConsent = false;
      }
    },
    declineConsent(newVal) {
      if (newVal) {
        this.acceptConsent = false;
        this.declineConsent = true;
      }
    },
  },
  methods: {
    toggleCheckbox(selection) {
      if (selection === 'accept') {
        this.dataConsent = true;
      } else if (selection === 'decline') {
        this.dataConsent = false;
      }
    },
    async addEvent(eventName, passName) {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    handleRadioChange(selection, options, index) {
      const selectedOption = options.find((option) => option.value === selection);
      this.$set(this.selectedAnswers, index, selectedOption);
      this.$set(this.radioErrors, index, false);
      this.checkAllAnswersSelected();
    },
    previousStep() {
      this.step -= 1;
      this.$emit('updateTrialStep', { step: this.step });
      this.addEvent('step3_prev_buton', 'Step3 Prev Button');
    },
    checkQuestionAnswered() {
      if (this.selectedAnswers) {
        this.totalValues = this.selectedAnswers.length;
        this.questionAnsweredValues = 0;
        this.selectedAnswers.forEach((question) => {
          this.questionAnsweredValues += parseInt(question.optionValue, 10);
        });
      }
    },
    nextStep() {
      let allQuestionsAnswered = true;
      this.questions.forEach((_, index) => {
        if (this.selectedAnswers[index] === undefined || this.selectedAnswers[index] === null) {
          allQuestionsAnswered = false;
          this.$set(this.selectErrors, index, true);
          this.$set(this.radioErrors, index, true);
        }
      });

      if (!allQuestionsAnswered) {
        return;
      }

      this.isFormInvalid = false;
      const questionsText = this.questions.map((question) => question.question);
      this.step += 1;
      this.$emit('updateTrialStep', {
        step: this.step,
        hospitals: this.centers,
        questions: questionsText,
        selectedAnswers: this.selectedAnswers,
      });
      this.checkQuestionAnswered();
      this.addEvent('step3_next_buton', 'Step3 Next Button');
    },
    trialNextStep() {
      this.step += 1;
      this.$emit('trialNextStep', {
        step: this.step,
      });
    },
    checkValidForm() {
      return (this.selectedAnswers.length === this.questions.length
        && this.selectedAnswers.length > 0
        && !this.selectedAnswers.includes(null)
        && !this.selectedAnswers.includes(undefined));
    },
    selectionChanged(selection, index) {
      this.$set(this.selectedAnswers, index, selection);
      this.checkAllAnswersSelected();
    },
    checkAllAnswersSelected() {
      if (this.selectedAnswers.length !== this.questions.length) {
        this.nextBtnDisable = true;
      } else {
        this.nextBtnDisable = !this.selectedAnswers.every(
          (answer) => answer !== null && answer !== undefined,
        );
      }
    },
    isYesNoQuestion(options) {
      if (options && options.length !== undefined) {
        if (options.length !== 2) {
          return false;
        }
        const lowerCaseOptions = options.map((option) => option.option.toLowerCase());
        return lowerCaseOptions.includes('sí') || lowerCaseOptions.includes('si') || lowerCaseOptions.includes('yes');
      }
      return false;
    },
    endStep() {
      if (this.dataConsent) {
        this.$emit('updateNotPassedSaveData', {});
      } else {
        this.$emit('updateNotPassedNoSaveData', {});
      }
      window.location.reload();
    },
    preventDoubleClick(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.accept-checkbox {
  font-weight: bold;
  margin-top: 0 !important;
}

.decline-checkbox {
  font-weight: bold;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

#rotate-to-portrait {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(249, 254, 254, 1);
  color: var(--highlightedPU);
  text-align: center;
  z-index: 10000;
  /* Asegura que se muestra sobre todo lo demás */
  align-items: center;
  justify-content: center;
}

.rotate-to-portrait-text {
  width: 70%;
  line-height: 2em;
  font-size: 1.4em;
  font-weight: bold;
}

.invalid-radio.v-input--selection-controls .v-radio>>>.v-label {
  color: red !important;
}

.invalid-select {
  border-color: red !important;
  color: red !important;
}

/* Modal Content */
.information-trial {
  z-index: 99;
  text-align: left;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(18, 197, 224, 0.4);
}

.modal-logo {
  margin-top: 2vh;
  margin-left: 1%;
  padding: 0;
}

.gradient-line {
  margin: auto;
  width: 90%;
  height: 3px;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
}

.modal-trial-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-trial-negative-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-trial-title {
  margin-top: 3vh;
  margin-left: 7%;
  font-family: 'Roboto-Regular';
  font-size: 1.3em;
  color: #33CCCC !important;
}

.modal-trial-negative-title {
  margin-top: 3vh;
  margin-left: 7%;
  font-family: 'Roboto-Regular';
  font-size: 1.3em;
  color: rgb(255, 106, 106);
}

.modal-trial-text {
  width: 85%;
  height: auto !important;
  margin-top: 20px;
  margin-bottom: 45px;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-trial-negative-text {
  width: 85%;
  height: auto !important;
  margin-top: 20px;
  margin-bottom: 45px;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-trial-button {
  margin-top: 5vh !important;
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.modal-trial-negative-button {
  margin-top: 5vh !important;
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.close {
  margin-top: -12px;
  margin-bottom: 0;
  color: var(--typographyPU);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* End Modal Content */
.sermes-logo {
  height: 12vh !important;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.language-icons {
  height: 10vh !important;
}

.step-title {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: 'Roboto-Regular';
  margin-left: 11%;
  font-size: 1.3em;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flag {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.form-side {
  width: 50%;
  overflow-y: auto;
  height: 100%;
}

.trial-buttons {
  margin-top: 0vh;
  padding-top: 0vh;
  padding-bottom: 7vh;
  width: 50%;
}

.form-card {
  box-shadow: none !important;
}

.trial-button {
  margin-top: 0vh;
  margin-bottom: 10vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

#trial-form-previous-button {
  width: 20%;
  height: 48px;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
}

#trial-form-next-button {
  width: 20%;
  height: 48px;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
  ;
}

/* Estilo para pantallas de móviles */
@media only screen and (max-width: 768px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 7vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 45%;
    height: 49px !important;
    font-size: 1em;
  }

  .main-container {
    height: auto;
    display: block;
  }

  .responsive-image {
    width: 100%;
    height: 20vh;
    margin-top: -3vh !important;
  }

  .form-side {
    width: 100%;
    height: auto;
  }

  .questions-content {
    padding: 20px !important;
    margin: auto;
    margin-top: 0px;
    width: 85%;
  }

  .trial-buttons {
    position: static;
    width: 100%;
  }

  .trial-button {
    margin-top: 0vh !important;
    margin-bottom: 10vh;
    width: 20vh !important;
    height: 7vh !important;
    font-size: 1em !important;
  }

}

/* Estilo para pantallas de monitores */
@media only screen and (min-width: 769px) {
  .responsive-image {
    top: 0;
    height: 100vh;
    width: 50%;
    position: fixed;
  }

  .form-side {
    width: 100% !important;
    padding: 0 10vh;
    padding-bottom: 10vh;
    left: 0%;
  }

  .trial-buttons {
    width: 100%;
  }

  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 10%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 20px;
    width: 50%;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 50%;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 20%;
    height: 49px !important;
    font-size: 1em;
  }

  .step-title {
    margin-top: 2vh;
    margin-bottom: 5vh;
    margin-left: 5vh !important;
    width: 100%;
    font-size: 1.4em !important;
  }

  .questions-content {
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    width: 90%;
  }

  #trial-form-previous-button {
    margin-top: 50px !important;
    margin-right: 3%;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 50px !important;
    margin-left: 3%;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }
}

/* Modal Content */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(18, 197, 224, 0.4);
}

.modal-logo {
  margin-top: 2vh;
  margin-left: 1%;
  padding: 0;
}

.modal-trial-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-trial-negative-content {
  background-color: #fefefe;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.7);
}

.modal-trial-title {
  margin-top: 3vh;
  margin-left: 7%;
  font-family: 'Roboto-Regular';
  font-size: 1.3em;
  color: rgb(105, 253, 105);
}

.modal-trial-negative-title {
  margin-top: 3vh;
  margin-left: 7%;
  font-family: 'Roboto-Regular';
  font-size: 1.3em;
  color: rgb(255, 106, 106);
}

.modal-trial-text {
  width: 85%;
  height: auto !important;
  margin-top: 20px;
  margin-bottom: 45px;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-trial-negative-text {
  width: 85%;
  height: auto !important;
  margin-top: 20px;
  margin-bottom: 45px;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-trial-button {
  margin-top: 25vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

.modal-trial-negative-button {
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

/* End Modal Content */
#trial-form {
  margin-top: 2vh;
}

#trial-form {
  padding: 0px 10px;
}

.trial-buttons {
  margin-top: 9vh;
}

.trial-button {
  margin-top: 5vh;
  margin-bottom: 2vh;
  text-align: center;
  width: 220px;
  height: 45px !important;
  font-family: 'Roboto-Bold';
  font-size: 0.9em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

#trial-form-previous-button {
  width: 20%;
  height: 48px;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
}

#trial-form-next-button {
  width: 20%;
  height: 48px;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
  ;
}

.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}

.button-disabled {
  cursor: not-allowed ! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

@media (min-width: 2561px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 1%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 20px;
    width: 50%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: 45vh;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 20%;
    height: 55px !important;
    font-size: 1.5em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 50%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 20% !important;
    height: 55px !important;
    font-size: 1.5em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    width: 20%;
    height: 53px;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    width: 20%;
    height: 53px;
    font-size: 1em;
  }
}

@media (min-width: 1441px) and (max-width: 2561px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 10%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 20px;
    width: 50%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 50%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 20%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    width: 20%;
    height: 45px !important;
    font-size: 1em;
  }
}

@media (min-width: 1365px) and (max-width: 1440px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 10%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    margin-top: 10vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    margin-top: 10vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 18%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }
}

@media (min-width: 1281px) and (max-width: 1365px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 10%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    margin-top: 10vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    margin-top: 10vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 18%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }
}

@media (min-width: 1180px) and (max-width: 1280px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 3vh;
    margin-left: 10%;
    height: 7vh !important;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    margin-top: 10vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    margin-top: 8vh !important;
    width: 60%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 18%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    width: 22%;
    height: 48px;
    font-size: 1em;
  }
}

@media (min-width: 1025px) and (max-width: 1179px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 10%;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 18%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 5vh !important;
    margin-bottom: 2vh;
    width: 27% !important;
    height: 42px !important;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 960px) and (max-width: 1024px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 10%;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 25% !important;
    height: 45px !important;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 821px) and (max-width: 959px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 6vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 25%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 27% !important;
    height: 50px !important;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 769px) and (max-width: 820px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 6vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 75%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 28%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 27% !important;
    height: 48px !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 7vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 7vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 7vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 65%;
    height: 100vh !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 45%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 6vh !important;
    margin-bottom: 2vh;
    width: 27% !important;
    height: 50px !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 380px) and (max-width: 480px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 1vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: 100vh !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 35%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 2vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: 100vh !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 45%;
    height: 49px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 32% !important;
    height: 48px !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh;
    margin-right: 3%;
    font-size: 1em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh;
    margin-left: 3%;
    font-size: 1em !important;
  }
}

@media (min-width: 281px) and (max-width: 379px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 7vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 1vh;
    margin-left: 7%;
    font-size: 1.3em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: 100vh !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.9em !important;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 40%;
    height: 49px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 2vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: 100vh !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.9em !important;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 45%;
    height: 45px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 35%;
    height: 42px !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh;
    margin-right: 3%;
    font-size: 0.8em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh;
    margin-left: 3%;
    font-size: 0.8em !important;
  }
}

@media (max-width: 280px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 1vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    width: 45%;
    height: 45px !important;
    font-size: 1em;
  }

  .modal-trial-negative-title {
    margin-top: 1vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 95%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 30px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 0.7em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 45%;
    height: 45px !important;
    font-size: 1em;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: 0;
  }

  .trial-button {
    margin-top: 4vh;
    margin-bottom: 2vh;
    width: 220px;
    height: 45px !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh;
    margin-right: 3%;
    width: 32% !important;
    height: 35px !important;
    font-size: 0.8em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh;
    margin-left: 3%;
    width: 32% !important;
    height: 35px !important;
    font-size: 0.8em !important;
  }
}

@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 1250px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: -4vh;
  }

  .trial-button {
    margin-bottom: 2vh;
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh !important;
    margin-right: 3%;
    font-size: 0.9em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh !important;
    margin-left: 3%;
    font-size: 0.9em !important;
  }
}

@media screen and (min-resolution: 2dppx) and (orientation: portrait) and (max-width: 375px) {
  .modal {
    padding-top: 10px;
    left: 0;
    top: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .modal-logo {
    margin-top: 0;
    margin-left: 0;
    height: 8vh !important;
    width: 100%;
    padding: 0;
  }

  .modal-trial-title {
    margin-top: 3vh;
    margin-left: 7%;
    font-size: 1.4em;
  }

  .modal-trial-content {
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    height: auto !important;
  }

  .modal-trial-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-button {
    margin-top: 5vh;
    margin-bottom: 0;
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  .modal-trial-negative-title {
    margin-top: 3vh;
    margin-left: 7% !important;
    font-size: 1.4em;
  }

  .modal-trial-negative-content {
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    height: auto !important;
  }

  .modal-trial-negative-text {
    width: 85%;
    height: auto !important;
    margin-top: 20px;
    margin-bottom: 45px;
    margin-left: 7%;
    font-size: 1em;
    text-align: left;
  }

  .modal-trial-negative-button {
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  #trial-form {
    padding: 0px 10px;
  }

  .trial-buttons {
    margin-top: -4vh;
  }

  .trial-button {
    margin-bottom: 2vh;
    width: 30% !important;
    height: 40px !important;
    font-size: 0.9em !important;
  }

  .button-enabled {
    border: solid var(--highlightedPU) 3px;
    color: var(--highlightedPU);
    opacity: 1;
  }

  .button-disabled {
    cursor: not-allowed ! important;
    border: solid var(--nonHighlightedTextPU) 3px;
    color: var(--nonHighlightedTextPU);
    opacity: 0.45;
  }

  #trial-form-previous-button {
    margin-top: 5vh !important;
    margin-right: 3%;
    font-size: 0.9em !important;
  }

  #trial-form-next-button {
    margin-top: 5vh !important;
    margin-left: 3%;
    font-size: 0.9em !important;
  }
}

/* Estilos para móviles en landscape */
@media screen and (max-width: 1023px) and (orientation: landscape) {
  #rotate-to-portrait {
    display: flex;
  }
}
</style>
