import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoadingImages: true,
    isLoadingData: true,
    trialDataLoaded: false,
    totalImages: 0,
    loadedImagesCount: 0,
    selectedLanguage: 'ES',
  },
  mutations: {
    setTotalImages(state, totalImages) {
      state.totalImages = totalImages;
    },
    incrementLoadedImagesCount(state) {
      state.loadedImagesCount += 1;
      if (state.loadedImagesCount === state.totalImages) {
        state.isLoadingImages = false;
      }
    },
    trialDataLoaded(state) {
      state.trialDataLoaded = true;
      if (state.trialDataLoaded) {
        state.isLoadingData = false;
      }
    },
    setSelectedLanguage(state, language) {
      state.selectedLanguage = language;
    },
  },
  actions: {
    setTotalImages({ commit }, totalImages) {
      commit('setTotalImages', totalImages);
    },
    incrementLoadedImagesCount({ commit }) {
      commit('incrementLoadedImagesCount');
    },
    trialDataLoaded({ commit }) {
      commit('trialDataLoaded');
    },
    setSelectedLanguage({ commit }, language) {
      commit('setSelectedLanguage', language);
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    selectedLanguage: (state) => state.selectedLanguage,
  },
});
